import React from 'react'
import '../App.css'
const Token = ({small, item}) => {
    return (
        <>
        {
            small? <div className='flex justify-between items-center gap-4'>
            <img className='w-[100px] h-[100px]' src={item?.image} />
            <div>
                <div className='flex items-center gap-2 text-[#60B1FE]'>
                    <p>created by</p>
                    <img className='w-[10px] h-[10px]' src={item?.emoji} />
                    <p>{item?.author}</p>
                </div>
                <p className='text-[#7CEFAC]'>market cap: {item?.mc} [badge: verified]</p>
                <p className='text-white'>replies: {item.replies}</p>
                <p className='font-bold text-white'>cat nap [ticker: {item?.ticker}]</p>
                <p className='text-white'>{item.desc}</p>

            </div>

        </div>:
        <div className={`flex justify- items-center gap-4 min-w-[30%] mb-10 ${item?.id==1 ?`shake bg-yellow-900` :``} `}>
            <img className='w-[120px] h-[120px]' src={item?.image} />
            <div>
                <div className='flex items-center gap-2 text-[#60B1FE]'>
                    <p>created by</p>
                    <img className='w-[10px] h-[10px]' src={item?.emoji} />
                    <p>{item?.author}</p>
                </div>
                <p className='text-[#7CEFAC]'>market cap: {item?.mc} [badge: verified]</p>
                <p className='text-white'>replies: {item.replies}</p>
                <p className='font-bold text-white'>cat nap [ticker: {item?.ticker}]</p>
                <p className='text-white'>{item.desc}</p>
            </div>
        </div>
        }
        </>
    )
}

export default Token