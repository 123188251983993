import React from 'react'
import '../App.css'
import logo from '../assets/plogo.svg'
import Chip from './Chip'
const Header = () => {
    return (
        <div className='header_wrapper'>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10 }}>
                <img src={logo} style={{ height: 50, width: 50 }} />
                <div className="block sm:flex">
                    <div className="mr-2">
                        <p className='text_a'>[telegram]</p>
                        <p className='text_a'>[twitter]</p>
                    </div>
                    <div style={{ display: 'flex', gap: '4px' }}>
                        <Chip animate={true} title={" 🐦 745vy6 bought 21.9892 SOL of PENISPUMP  🐼 "} />
                        <Chip hideOnMobile bg={"#93C5FD"} animate={false} title={"🐕 745vy6 bought 21.9892 SOL of PENISPUMP 🐦"} />
                    </div>
                </div>
            </div>

            <div>
                <p className='text_a'>[connect wallet]</p>
            </div>
        </div>
    )
}

export default Header