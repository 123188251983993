import React from 'react'
import Token from './Token';
import img1 from '../assets/plogo.svg'
function Head() {
    const data={
        id: 133,
        title: "Penispump",
        desc: "Pump it up",
        mc: "22 mill",
        replies: "42,069",
        image:img1,
        author:"KING",
        ticker:'$PEPUMP',
        emoji:"https://w7.pngwing.com/pngs/208/565/png-transparent-toby-maguire-internet-meme-know-your-meme-spider-man-meme-face-head-meme-thumbnail.png"
    }
  return (
    <div >
        <p className='text-[white] text-[28px] pt-[10px] text-center pb-[10px]'>[start a new coin]</p>
        <div className='flex justify-center items-center mb-10 mt-10'>
            <Token item={data}/>
        </div>
        <div className='flex justify-center items-center'>
            <div className='flex justify-between gap-2 items-center'>
                <input className='bg-[#86EFAC] p-2 py-2 text-[12px] rounded-[5px]' placeholder='search for tokens'/>
                <div>
                    <p className='bg-[#86EFAC] p-2 py-2 text-[12px] rounded-[5px]'>search</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Head;