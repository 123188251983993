import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Head from './components/Head';
import List from './components/List';

function App() {
  return (
    <div className="wrapper">
      <Header/>
      <Head/>
      <List/>
    </div>
  );
}

export default App;
