import React from 'react'
import '../App.css'
function Chip({title, bg, animate, hideOnMobile}) {
  return (
    <div className={`${animate?'shake':''} ${hideOnMobile ? 'hidden text-[12px] sm:flex sm:items-center sm:text-[10px] ':'flex items-center text-[10px]'}`} style={{backgroundColor:bg?bg:'#86EFAC', padding:'5px 10px', borderRadius:'5px'}}>
        {title}
    </div>
  )
}

export default Chip