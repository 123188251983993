import React from 'react'
import Token from './Token'
import img1 from '../assets/pp/img1.jpg'
import img2 from '../assets/pp/img2.jpg'
import img3 from '../assets/pp/img3.jpg'
import img4 from '../assets/pp/img4.jpg'
import img5 from '../assets/pp/img5.jpg'
import img6 from '../assets/pp/img6.jpg'
import img7 from '../assets/pp/img7.jpg'

function List() {
    const data = [
        {
            id: 1,
            title: "cat nap",
            author:"bikky pole",
            desc: "cat nap desc",
            mc: "9k",
            replies: "2",
            ticker:'$CATNP',
            image:img1,
            emoji:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRydG3mMQJbQWiKIco9dqcjIaOn72oHKG-cxw&s"
        },
        {
            id: 2,
            title: "Lady with a dog",
            desc: "cI have a Dog",
            mc: "2.3k",
            replies: "2",
            author:"SOL gang",
            ticker:'$LADYBOY',
            image:img2,
            emoji:"https://png.pngtree.com/png-vector/20211027/ourmid/pngtree-don-t-joke-with-me-funny-cat-meme-vector-png-image_4010968.png"
        },
        {
            id: 3,
            title: "book of fags",
            desc: "Holy book",
            mc: "9.3k",
            replies: "33",
            author:"poli coli",
            ticker:'$BOFA',
            image:img3,
            emoji:"https://i.pinimg.com/originals/c9/b0/d9/c9b0d92dea3fe67a2cc0c8d2c225a828.png"
        },
        {
            id: 4,
            title: "We All Gay",
            desc: "gayniggas from outta space",
            mc: "783.33k",
            replies: "373",
            author:"ET",
            ticker:'$GFOS',
            image:img4,
            emoji:"https://p7.hiclipart.com/preview/345/350/358/spider-man-drawing-internet-meme-know-your-meme-spiderman.jpg"
        },
        {
            id: 5,
            title: "gaymestop",
            desc: "moon is lost somewhere in the dump",
            mc: "11.9k",
            replies: "12",
            author:"OG me",
            ticker:'$GSS',
            image:img5,
            emoji:"https://w7.pngwing.com/pngs/101/742/png-transparent-andras-arato-pain-know-your-meme-meme-face-head-meme.png"
        },
        {
            id: 6,
            title: "fagio",
            desc: "fagio, fade away ",
            mc: "67.4k",
            replies: "44",
            author:"unkowwwn",
            ticker:'$FFA',
            image:img6,
            emoji:"https://png.pngtree.com/png-vector/20230408/ourmid/pngtree-vector-dog-memes-png-image_6695645.png"
        },
        {
            id: 7,
            title: "ass hunter",
            desc: "who hunts fish, i hunt ass",
            mc: "121k",
            replies: "21",
            author:"hunter",
            ticker:'$ASSHUNT',
            image:img7,
            emoji:"https://i.pngimg.me/thumb/f/720/comdlpng6947861.jpg"
        }
    ]
  return (
    <div className='h-auto min-h-[700px] flex flex-wrap justify-between items-center px-1 sm:px-10 pt-8'>
        {
            data.map((item, index) => {
                return <Token small={false} item={item}/>
            })  
        }
    </div>
  )
}

export default List